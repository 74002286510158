export const genres = [
  { id: 28, name: "Action" },
  { id: 12, name: "Adventure" },
  { id: 16, name: "Animation" },
  { id: 35, name: "Comedy" },
  { id: 80, name: "Crime" },
  { id: 99, name: "Documentary" },
  { id: 18, name: "Drama" },
  { id: 10751, name: "Family" },
  { id: 14, name: "Fantasy" },
  { id: 36, name: "History" },
  { id: 27, name: "Horror" },
  { id: 10402, name: "Music" },
  { id: 9648, name: "Mystery" },
  { id: 10749, name: "Romance" },
  { id: 878, name: "Science Fiction" },
  { id: 10770, name: "TV Movie" },
  { id: 53, name: "Thriller" },
  { id: 10752, name: "War" },
  { id: 10766, name: "Soap" },
  { id: 10767, name: "Talk" },
  { id: 10768, name: "War & Politics" },
  { id: 37, name: "Western" },
  { id: 10759, name: "Action & Adventure" },
  { id: 10762, name: "Kids" },
  { id: 10765, name: "Sci-Fi & Fantasy" },
];
export const movies = {
  dates: {
    maximum: "2023-09-17",
    minimum: "2023-07-31",
  },
  page: 1,
  results: [
    {
      adult: false,
      backdrop_path: "/8pjWz2lt29KyVGoq1mXYu6Br7dE.jpg",
      genre_ids: [28, 878, 27],
      id: 615656,
      original_language: "en",
      original_title: "Meg 2: The Trench",
      overview:
        "전 세계에서 가장 깊은 마리아나 해구, 역사상 가장 거대하고 무자비한 포식자가 도사리는 그곳에서 강철 다이버 ‘조나스’(제이슨 스타뎀)가 더 맹렬하게 돌아온 ‘메가로돈’과 짜릿한 대결을 펼치는 액션 어드벤처",
      popularity: 2429.447,
      poster_path: "/cbAHK6Vrt0GClMRUxH8TsgC2JqL.jpg",
      release_date: "2023-08-15",
      title: "메가로돈 2",
      video: false,
      vote_average: 7,
      vote_count: 1790,
    },
    {
      adult: false,
      backdrop_path: "/w2nFc2Rsm93PDkvjY4LTn17ePO0.jpg",
      genre_ids: [16, 35, 28],
      id: 614930,
      original_language: "en",
      original_title: "Teenage Mutant Ninja Turtles: Mutant Mayhem",
      overview:
        "뉴욕의 하수구로 스며든 미스터리한 녹색 액체, ‘우즈’로 인해 아기 거북이 네 마리와 청년 쥐 스플린터는 사람과 비슷한 모습의 돌연변이가 된다. 15년 후, 10대 청소년이 된 레오나르도, 미켈란젤로, 라파엘, 도나텔로는 하수구에 숨어 사는 생활에서 벗어나 인간 세상에서 평범한 삶을 살고 싶어하고 우연히 만난 학생 기자 에이프릴과 함께 도시를 위협하는 '슈퍼플라이'와 그 일당을 물리치면 뉴욕의 히어로가 되어 당당히 세상에 나갈 수 있을 거라는 꿈을 꾸는데… 인간들을 모두 돌연변이로 만들겠다는 ‘슈퍼플라이’의 음모로 위험에 빠진 뉴욕! 틴에이저 히어로로 다시 태어난 ‘닌자터틀’ 형제들의 스트릿 액션 어드벤처가 펼쳐진다!",
      popularity: 800.947,
      poster_path: "/6xORIhe6cP3iM5RYTHs7MTOH1qa.jpg",
      release_date: "2023-09-14",
      title: "닌자터틀: 뮤턴트 대소동",
      video: false,
      vote_average: 7.3,
      vote_count: 512,
    },
    {
      adult: false,
      backdrop_path: "/fm6KqXpk3M2HVveHwCrBSSBaO0V.jpg",
      genre_ids: [18, 36],
      id: 872585,
      original_language: "en",
      original_title: "Oppenheimer",
      overview:
        "세상을 구하기 위해 세상을 파괴할 지도 모르는 선택을 해야 하는 천재 과학자의 핵개발 프로젝트.",
      popularity: 530.049,
      poster_path: "/4ZLnVUfiCe3wX8Ut9eyujndpyvA.jpg",
      release_date: "2023-08-15",
      title: "오펜하이머",
      video: false,
      vote_average: 8.3,
      vote_count: 3566,
    },
    {
      adult: false,
      backdrop_path: "/2zzaJ9jzyK1Am8XoS0dFjmP8V0L.jpg",
      genre_ids: [28, 53, 27],
      id: 1135229,
      original_language: "en",
      original_title: "I Am Rage",
      overview:
        "에린은 새로운 남자친구의 고향인 어느 한적한 시골마을을 방문한다. 그곳에서 비슷하게 여행을 온 커플들을 만나게 되고 그들은 실종이 되어도 누구도 찾지 않은 외로운 사람들이라는 공통점이 있다는 것을 알게 된다. 그들은 인간의 피를 거래하고 마시며 초자연적인 힘을 얻는 사이비 살인마 집단에 납치된 것. 하지만 에린은 위기에 몰리거나 분노하게 되면 극한의 잔인함과 폭력성이 초인적으로 발현되는 특이한 혈통을 가진 체질이다. 에린의 분노가 폭발하고 그녀를 사냥한 악인들에 대한 더욱 잔혹한 사냥이 시작된다.",
      popularity: 425.756,
      poster_path: "/z1nFd03qoiCrHlGSxBlumkF9uZh.jpg",
      release_date: "2023-09-14",
      title: "론 서바이버 : 아이 엠 리벤지",
      video: false,
      vote_average: 6.5,
      vote_count: 11,
    },
    {
      adult: false,
      backdrop_path: "/kHlX3oqdD4VGaLpB8O78M25KfdS.jpg",
      genre_ids: [9648, 18, 80],
      id: 945729,
      original_language: "en",
      original_title: "A Haunting in Venice",
      overview:
        "세계적 명탐정 '에르큘 포와로'는 오랜 탐정 생활에서 은퇴하여 아름다운 도시 베니스에서 평범한 삶을 살아가고 있다. 그런 그에게 오랜 친구이자 베스트셀러 작가인 '아리아드네 올리버'가 찾아와 죽은 영혼을 부를 수 있다고 알려진 영험한 심령술사의 실체를 밝혀달라고 부탁하게 된다.핼러윈 밤, 베니스 운하 위 위치한 고풍스러운 저택의 주인이자 1년 전 사랑하는 딸을 잃고 깊은 상실에 빠진 '로웨나 드레이크'의 초대로 교령회에 참석한 이들은 미스터리한 심령술사 '조이스 레이놀즈'가 죽은 영혼의 목소리를 전하는 광경을 보며 혼란에 빠지게 된다. 갑자기 발생한 끔찍한 살인 사건에 실체 없는 용의자를 쫓던 '에르큘 포와로'는  자신의 모든 믿음이 흔들리는 경험을 하며 충격에 빠지게 되는데....",
      popularity: 348.761,
      poster_path: "/dUQdookYulZV9sTIEvAuAu1Ptxv.jpg",
      release_date: "2023-09-13",
      title: "베니스 유령 살인사건",
      video: false,
      vote_average: 6.8,
      vote_count: 203,
    },
    {
      adult: false,
      backdrop_path: "/5Jfk2p3iGrChUKkl0cBD5krr2u8.jpg",
      genre_ids: [16, 10749],
      id: 998731,
      original_language: "ja",
      original_title: "映画 佐々木と宮野ー卒業編ー",
      overview:
        "여성스러운 얼굴이 콤플렉스인 고등학교 2학년 ‘미야노’와 불량해 보이는 3학년 선배 ‘사사키’. BL 만화를 빌려 주면서 시작된 두 사람은 마침내 사랑에 빠진다. 어느덧 계절은 겨울로 바뀌고 ‘사사키’의 졸업이 코 앞으로 다가온다 두 사람은 함께하는 고교 생활을 소중하게 보내지만, 졸업이 다가올수록 애틋한 마음은 커져만 가는데… 고민도 불안도 날려 버리고, 언제나 아껴 주고 싶은 마음을 전하고 싶어!",
      popularity: 204.668,
      poster_path: "/4XeVvKoPKl5Mbiu6ZN3esMt51e7.jpg",
      release_date: "2023-09-06",
      title: "극장판 사사키와 미야노 - 졸업편",
      video: false,
      vote_average: 6.3,
      vote_count: 15,
    },
    {
      adult: false,
      backdrop_path: "/urDWNffjwmNi5IQaezw9GwqkUXa.jpg",
      genre_ids: [12, 14],
      id: 767,
      original_language: "en",
      original_title: "Harry Potter and the Half-Blood Prince",
      overview:
        "어둠의 세력이 더욱 강력해져 머글 세계와 호그와트까지 위협해온다. 위험한 기운을 감지한 덤블도어 교수는 다가올 전투에 대비하기 위해 해리 포터와 함께 대장정의 길을 나선다. 볼드모트를 물리칠 수 있는 유일한 단서이자 그의 영혼을 나누어 놓은 7개의 호크룩스를 파괴하는 미션을 수행해야만 하는 것! 또한 덤블도어 교수는 호크룩스를 찾는 기억여행에 결정적 도움을 줄 슬러그혼 교수를 호그와트로 초청한다. 한편 학교에서는 계속된 수업과 함께 로맨스의 기운도 무르익는다. 해리는 자신도 모르게 지니에게 점점 끌리게 되고, 새로운 여자 친구가 생긴 론에게 헤르미온느는 묘한 질투심을 느끼는데...",
      popularity: 143.657,
      poster_path: "/yrGlb3HLtjGp39mv4MJ5UuIHcj4.jpg",
      release_date: "2023-09-06",
      title: "해리 포터와 혼혈왕자",
      video: false,
      vote_average: 7.7,
      vote_count: 18104,
    },
    {
      adult: false,
      backdrop_path: "/lUPz9zW0uf0bHfYsRRID8RtBHEU.jpg",
      genre_ids: [18, 36],
      id: 457232,
      original_language: "en",
      original_title: "Lamborghini: The Man Behind the Legend",
      overview:
        "2차 세계대전이 종식된 후 고향으로 돌아온 ‘페루치오 람보르기니’  그는 트랙터 회사를 세워 자신의 이름을 널리 알리겠다는 야망을 품고있다.  타협 없는 노력 끝에 결국 트랙터 개발에 성공하였지만, 그의 꿈은 여기서 멈추지 않는다.  페루치오는 동경하는 자동차 제조사 회장 ‘엔초 페라리’를 찾아가 동업을 제안하지만,  시골 촌놈 취급을 받으며 거절당하는 굴욕을 당한다. 이에 격분한 페루치오는 업계에서 유능하다고 알려진 자동차 엔지니어를 스카우트하며, 황소같이 강력한 차를 만들기위해 의기투합한다. 제네바 모터쇼까지 6개월밖에 남지 않은 상황에서 페루치오는 정말 세상에 선보인 적 없던 최고의 차를 만들어 낼 수 있을까?",
      popularity: 112.035,
      poster_path: "/1z6EUqJn07mtfmYGzu8LmHfiOJW.jpg",
      release_date: "2023-08-23",
      title: "람보르기니: 전설이 된 남자",
      video: false,
      vote_average: 6.3,
      vote_count: 300,
    },
    {
      adult: false,
      backdrop_path: "/fIQfdZ6fqf9mIbqBaexbgIEIk5K.jpg",
      genre_ids: [35],
      id: 864168,
      original_language: "en",
      original_title: "Joy Ride",
      overview:
        "성공 가도를 달리던 알파걸 변호사 '오드리'(애슐리 박)는 초고속 승진을 위해 어릴 적 헤어진 생모를 찾아오라는 황당한 미션을 받는다.  꽃미남 전문가인 음란마귀 아티스트 '롤로'(셰리 콜라), 흑역사 숨기고 할리우드 진출 앞둔 톱배우 '캣'(스테파니 수), 흐린 눈의 케이팝 광인 '데드아이'(사브리나 우)가 합류하면서 네 친구들의 크레이지한 월드투어가 시작된다!  지구 반 바퀴를 돌고 도는 고생길 끝에 밝혀진 오드리의 출생의 비밀은…",
      popularity: 102.202,
      poster_path: "/dY2HZ0yAMhYqwHkuKM5HU7zxoJ9.jpg",
      release_date: "2023-08-30",
      title: "조이 라이드",
      video: false,
      vote_average: 6.6,
      vote_count: 160,
    },
    {
      adult: false,
      backdrop_path: "/dvHKdXijoN1OEzGcPhd0eRvkfMd.jpg",
      genre_ids: [27, 53, 28, 12],
      id: 960258,
      original_language: "en",
      original_title: "Shark Bait",
      overview:
        "마지막 봄 방학을 기념하러 해변에서 파티를 벌이던 젊은이들은 우연히 주인 없이 방치된 제트스키 두 대를 발견하고 키를 훔친다. 그들은 제트스키가 처참한 결과를 몰고 올 재앙의 원인이 될지 모른 채 승선한다. 철없는 치킨게임을 하다 고장 난 제트스키는 바다 한 가운데 고립되고 거대한 백상어의 그림자가 서서히 그들 주위를 맴돈다. 상어의 희생자가 한 명씩 늘어가는 가운데 남은 사람들은 해변으로 돌아가기 위해 고군분투한다.",
      popularity: 69.423,
      poster_path: "/pxsn8GtNHbN01iWkD2cV8CMuGzm.jpg",
      release_date: "2023-08-03",
      title: "47시간: 샤크베이트",
      video: false,
      vote_average: 5.8,
      vote_count: 261,
    },
    {
      adult: false,
      backdrop_path: "/7TdVWAO7vV9j1OLDq1hQJS3pb4U.jpg",
      genre_ids: [28, 35, 12, 53],
      id: 739405,
      original_language: "en",
      original_title: "Operation Fortune: Ruse de Guerre",
      overview:
        "전 세계 검은 무기 거래 왕, 슈퍼 빌런 ‘그렉’  그가 정체를 파악조차 불가능한 ‘핸들’을 거래한다는  첩보 내용이 입수된다. 국가 정보국은 전세계를 붕괴시킬 수도 있는  이것이 무엇인지 대혼돈에 빠지게 되고… 이 혼돈을 해결할 자는 오직 하나! 액션 만랩, 수트 핏과 꿀성대까지! 모든 것이 완벽한 업계 최고의 스파이 ‘포춘’ 무비 스타, 테크 전문가와 샷건 마스터까지 팀 ‘포춘’을 조합한 후 일생일대의 위험한 임무에 나서게 되는데…",
      popularity: 58.973,
      poster_path: "/5QXoAgO905YGucMJmeDb4CmjEUT.jpg",
      release_date: "2023-08-30",
      title: "스파이 코드명 포춘",
      video: false,
      vote_average: 6.6,
      vote_count: 1031,
    },
    {
      adult: false,
      backdrop_path: "/63CDysgJZb38ZXdw2yF1UuDJgDN.jpg",
      genre_ids: [53],
      id: 979924,
      original_language: "en",
      original_title: "On the Line",
      overview:
        "심야 라디오 '온 더 라인'의 진행자 엘리스가 생방송 중 의문의 전화를 받게 되고, 쇼맨은 그의 온 가족을 죽이겠다고 협박을 한다. 모든 것이 실시간으로 진행되는 라디오에서 사랑하는 사람을 구하기 위한 엘리스의 고군분투 생존게임이 시작되는데...",
      popularity: 50.437,
      poster_path: "/o04KUuO9Zoi1L8KxH5Z4jJF7iXb.jpg",
      release_date: "2023-08-02",
      title: "온 더 라인",
      video: false,
      vote_average: 6.1,
      vote_count: 382,
    },
    {
      adult: false,
      backdrop_path: "/qAvou7F5P4VcIR72JzzrnKEQSN3.jpg",
      genre_ids: [12, 14, 10751],
      id: 630,
      original_language: "en",
      original_title: "The Wizard of Oz",
      overview:
        "회오리 바람에 휩쓸려 오즈의 나라로 내던져진 도로시는 집으로 되돌아갈 수 있는 유일한 길이 위대한 오즈의 마법사를 만나는 것임을 알고 그를 찾아 긴 여정이 시작된다. 도로시는 애견 토토와 함께 노란 길을 따라 오즈의 마법사가 사는 에메랄드 시티로 향한다. 도중에 만난 세 명의 친구들, 지능을 얻고자 하는 허수아비와 심장을 원하는 양철 나뭇꾼, 용기를 가지고 싶어하는 겁장이 사자와 함께 오즈의 마법사에게 자신의 소원이 이루어지도록 부탁하기 위해 도로시와 함께 경쾌한 발걸음을 옮긴다. 그러나 도로시 일행을 방해하기 위해 뒤쫓아오는 서쪽 나라 마녀의 검은 그림자.",
      popularity: 53.375,
      poster_path: "/9FzHxca1OybjwOdTmWLfhdD4u0g.jpg",
      release_date: "2012-05-03",
      title: "오즈의 마법사",
      video: false,
      vote_average: 7.6,
      vote_count: 5105,
    },
    {
      adult: false,
      backdrop_path: "/rHnANzYUmV3WZw3n0yWOLiR3pen.jpg",
      genre_ids: [53],
      id: 976912,
      original_language: "en",
      original_title: "Graphic Desires",
      overview:
        "친구의 소개로 데이팅 앱에 가입한 '프랭크'  연인 몰래 앱을 통해 만난 여성에게 강한 성적 끌림을 느낀 그는 다시 그녀를 찾지만 여자는 사라지고 계정도 비활성화된다.  현실과 가상을 넘나들며 그녀를 찾아 헤매는 동안 그는 점점 데이팅 앱에 중독되고 결국 예기치 못한 사건에 휘말리고 마는데∙∙∙",
      popularity: 46.565,
      poster_path: "/8EHehEMsJVIPp2n2ROhZU8dbpJC.jpg",
      release_date: "2023-08-31",
      title: "그래픽 디자이어",
      video: false,
      vote_average: 1,
      vote_count: 1,
    },
    {
      adult: false,
      backdrop_path: "/wNYUv8u5Z0AI4dkyxGyet7eLEWm.jpg",
      genre_ids: [18],
      id: 945675,
      original_language: "zh",
      original_title: "一周的朋友",
      overview:
        "일주일마다 친구에 대한 기억을 잃는 전학생 ‘린샹즈’ 병 때문에 자발적 아싸가 돼버린 그녀에게 성화 재수학원 최고 인싸 3인방 ‘쉬유수’, ‘송샤오난’, ‘장우'가 다가온다.  “일주일만 기억해도 상관없어. 일주일간 친구 하면 되잖아!” 머리가 좋아지는 호박죽, 기억력 증진 댄스, 깜찍한 유수표 엄지 인형까지! 세 사람은 샹즈의 단 하나뿐인 ‘일주일간 친구’가 되기 위해 고군분투하고, 샹즈는 그들과의 추억을 일기장에 채워 나가기 시작한다.",
      popularity: 51.949,
      poster_path: "/bCp45FrpSWD0mEx2EGHkvxOs2OK.jpg",
      release_date: "2023-09-06",
      title: "일주일간 친구",
      video: false,
      vote_average: 5.7,
      vote_count: 110,
    },
    {
      adult: false,
      backdrop_path: "/z6GxNP2Z5AyoFfrYKxZWJypfZXJ.jpg",
      genre_ids: [16, 10751],
      id: 823609,
      original_language: "en",
      original_title: "Monster Family 2",
      overview:
        "몬스터에서 인간으로 겨우 돌아온 ‘위시본’ 패밀리!  새 가족이 된 전설 속 몬스터 ‘바바 야가’와 ‘렌필드’의 결혼식 날, 그들은 슈퍼 소녀 ‘밀라’에게 납치당한다. 이들뿐 아니라 드라큘라, 예티, 네시, 그리고 킹 콩가까지!! ‘위시본’ 패밀리는 ‘밀라’에 의해 전 세계 몬스터들이 납치된 것을 알게 되는데…  몬스터들을 구하기 위해 다시 몬스터로 변한 ‘위시본’ 패밀리! 과연 ‘위시본’ 가족은 몬스터들을 구하고 다시 인간으로 돌아갈 수 있을까?",
      popularity: 21.008,
      poster_path: "/9kPiLqqWlwCVL0KGJeM4kcZA9q9.jpg",
      release_date: "2023-08-02",
      title: "몬스터 패밀리 2",
      video: false,
      vote_average: 6.6,
      vote_count: 63,
    },
    {
      adult: false,
      backdrop_path: "/6Lqs0Bw2L2ZoVgx9XpmeN7vBcr0.jpg",
      genre_ids: [18],
      id: 960275,
      original_language: "fr",
      original_title: "Maestro(s)",
      overview:
        "권위 있는 빅투아르 음악상을 수상하며 차세대 거장으로 자리매김한 지휘자 ‘드니 뒤마르’. 그가 넘어야 할 가장 큰 산은 다름 아닌 같은 지휘자이자 음악계의 거장인 아버지 ‘프랑수아 뒤마르’이다.  한편, 아버지 ‘프랑수아’는 존경받는 최고의 지휘자지만 곧 정상의 위치에서 밀려날지도 모른다는 위기감과 함께, 같은 분야에서 승승장구하는 아들 ‘드니’에게 묘한 경쟁심을 느끼고 있다.  그러던 중 ‘프랑수아’는 평생을 소망하던, 이탈리아 ‘라 스칼라’ 극장의 지휘자 자리를 제안하는 전화를 받고 뛸 듯이 기뻐한다. 하지만 아들 ‘드니’는 자신에게 가야 할 제안이 아버지에게 잘못 전달되었음을 알게 되고, 인생 최대의 고민과 마주한다.  꿈의 무대를 두고 마주한 아버지와 아들! ‘라 스칼라’ 무대에 서게 될 주인공은 과연 누구일까?",
      popularity: 36.246,
      poster_path: "/8CNn2HuBMQYOA425MbAoc3oeTNO.jpg",
      release_date: "2023-08-09",
      title: "마에스트로",
      video: false,
      vote_average: 5.7,
      vote_count: 36,
    },
    {
      adult: false,
      backdrop_path: "/8nLiWpgxOfH15FjBgTvvcah3ZUb.jpg",
      genre_ids: [28, 53, 80, 35],
      id: 1015963,
      original_language: "en",
      original_title: "High Heat",
      overview:
        "전직 특수요원 출신 스타 셰프 '아나'(올가 쿠릴렌코)가 마피아의 타겟이 된 레스토랑을 구하기 위해 킬러 본능을 다시 일깨운다.",
      popularity: 24.199,
      poster_path: "/wIFYnvvdwsjD0Wjeg8MjIIp58rR.jpg",
      release_date: "2023-08-17",
      title: "킬러의 레스토랑",
      video: false,
      vote_average: 5.6,
      vote_count: 98,
    },
    {
      adult: false,
      backdrop_path: "/4hAhZ66n5iasGtSYHTFg6oGdJk0.jpg",
      genre_ids: [53, 27, 878],
      id: 660942,
      original_language: "no",
      original_title: "De uskyldige",
      overview:
        "이다와 안나는 새로운 아파트로 이사한 직후, 또래인 벤자민, 아이샤와 친구가 된다. 네 명의 아이들은 어른이 개입하지 않는 순간, 특별한 잠재력을 깨워나가기 시작하고 벤자민은 능력을 이용해 사람들을 조정할 수 있게 된다. 단순한 호기심과 장난으로 행해지던 어떤 일들이, 급기야 분노라는 감정과 이어지고 결국 친구들을 비롯해 주변에 위협을 가하기 시작하는 벤자민. 가장 순수하고, 본능적이었던, 그래서 더욱 파괴적이고 잔인할 수 있었던 잔혹한 동심의 세계가 펼쳐진다!",
      popularity: 22.179,
      poster_path: "/tNJ2oOuTPHny3x633DwwKDnc5mQ.jpg",
      release_date: "2023-09-06",
      title: "이노센트",
      video: false,
      vote_average: 7,
      vote_count: 439,
    },
    {
      adult: false,
      backdrop_path: "/g8kCHQzfogy1t1dE9nPBtiFkz64.jpg",
      genre_ids: [53, 878, 18],
      id: 729854,
      original_language: "ko",
      original_title: "콘크리트 유토피아",
      overview:
        "대지진으로 하루아침에 폐허가 된 서울. 모든 것이 무너졌지만 단 한 곳, 황궁 아파트만은 그대로다. 소문을 들은 외부 생존자들이 황궁 아파트로 몰려들자 위협을 느끼기 시작하는 입주민들. 생존을 위해 하나가 된 그들은 새로운 주민 대표 영탁을 중심으로 외부인의 출입을 철저히 막아선 채 아파트 주민만을 위한 새로운 규칙을 만든다. 덕분에 지옥 같은 바깥 세상과 달리 주민들에겐 더 없이 안전하고 평화로운 유토피아 황궁 아파트. 하지만 끝이 없는 생존의 위기 속 그들 사이에서도 예상치 못한 갈등이 시작되는데...",
      popularity: 22.278,
      poster_path: "/9dENCKupUckoT1WgOohHMZfVl61.jpg",
      release_date: "2023-08-09",
      title: "콘크리트 유토피아",
      video: false,
      vote_average: 8,
      vote_count: 3,
    },
  ],
  total_pages: 5,
  total_results: 83,
};

export const Tvs = {
  page: 1,
  results: [
    {
      backdrop_path: "/7BzmK4MWc0cj4hCxwWURt6TS6UR.jpg",
      first_air_date: "2023-08-31",
      genre_ids: [16, 10765, 10759, 35],
      id: 131378,
      name: "어드벤처 타임: 피오나와 케이크",
      origin_country: ["US"],
      original_language: "en",
      original_name: "Adventure Time: Fionna & Cake",
      overview: "",
      popularity: 244.518,
      poster_path: "/fi1b6U1kp73xheECzqwzMn8u3mX.jpg",
      vote_average: 9.3,
      vote_count: 117,
    },
    {
      backdrop_path: "/9faGSFi5jam6pDWGNd0p8JcJgXQ.jpg",
      first_air_date: "2008-01-20",
      genre_ids: [18, 80],
      id: 1396,
      name: "브레이킹 배드",
      origin_country: ["US"],
      original_language: "en",
      original_name: "Breaking Bad",
      overview:
        "2008년 1월 AMC에서 방영을 시작한 범죄 스릴러. Breaking Bad는 막가기를 뜻하는 미국 남부 지방의 속어이다. 한때 노벨화학상까지 바라 볼 정도로 뛰어난 과학자였던 고등학교 화학 교사 월터 화이트는 자신의 50세 생일 날에 폐암 3기 진단을 받는다. 어느 날 동서와 함께 마약 단속 현장을 참관한 그는 현장에서 달아나는 옛 제자 제시를 발견한다. 뇌성마비에 걸린 고등학생 아들과 임신한 아내를 위해 제시에게 동업을 제의한 월터는 자신의 화학지식을 이용해 전례없는 고순도 고품질의 메스암페타민을 제조한다.",
      popularity: 427.148,
      poster_path: "/3xnWaLQjelJDDF7LT1WBo6f4BRe.jpg",
      vote_average: 8.9,
      vote_count: 12491,
    },
    {
      backdrop_path: "/rkB4LyZHo1NHXFEDHl9vSD9r1lI.jpg",
      first_air_date: "2021-11-06",
      genre_ids: [16, 18, 10765, 10759],
      id: 94605,
      name: "아케인",
      origin_country: ["US"],
      original_language: "en",
      original_name: "Arcane",
      overview:
        "2021년 11월 Netflix에서 방영을 시작한 애니메이션. 라이엇 게임즈의 League of Legends를 원작으로 한다. 지상 도시 필트오버와 그 아래의 지하 도시 자운. 극심하게 반목하는 두 쌍둥이 도시에서, 두 자매가 서로 반대편에 서서 싸우기에 이른다. 마법 기술과 신념의 충돌 속에서 전쟁이 시작된다.",
      popularity: 189.618,
      poster_path: "/fqldf2t8ztc9aiwn3k6mlX3tvRT.jpg",
      vote_average: 8.7,
      vote_count: 3364,
    },
    {
      backdrop_path: "/4Mt7WHox67uJ1yErwTBFcV8KWgG.jpg",
      first_air_date: "1999-10-20",
      genre_ids: [10759, 35, 16],
      id: 37854,
      name: "원피스",
      origin_country: ["JP"],
      original_language: "ja",
      original_name: "ワンピース",
      overview:
        "해적왕을 꿈꾸는 소년 루피와 동료들의 유쾌한 모험담을 그린 작품! 힘있는 연출과 개성있는 그림체 및 캐릭터들의 활기찬 묘사로 인기를 모았으며, 1억 만부 이상 판매된 원작만화의 인기를 바탕으로 다양한 버전으로 제작된 초히트작 애니메이션이다.\n\n˝최고의 해적왕˝을 꿈꾸는 루피와 동료들의 파란만장한 모험담 !\n\n떄는 해적들이 난무하는 ‘춘추전국시대’. 곳곳에서는 전설적인 해적왕 골드로저가 남긴 보물 ‘원피스’를 놓고 수많은 해적들이 서로 싸우고 있었다. 어릴 때부터 골드로저를 너무도 동경한 나머지 자신도 전설의 해적왕이 되는 것이 꿈인 주인공 루피. 그러나 루피는 악마의 열매를 먹은 이후로 몸을 자유자재로 늘일 수 있지만 수영을 할 수가 없는 처지이다. 바다가 주 활동무대인 해적에게 있어 수영은 필수. 그러나 해적왕을 꿈꾸는 루피는 수영을 할 수 없음에도 불구하고 물에 빠지지 않으면 된다면서 자신의 꿈을 향해 모험을 떠난다. 그 과정에서 만나게 되는 현상금 헌터 ‘조로’, 자칭 해적전문 도둑인 ‘나미’, 저격수 ‘우솝, 그리고 주방장 ‘상디’까지. 지금부터 루피와 그의 동료 4명이 함께하는 해적의 꿈을 향한 파란만장한 모험이 펼쳐진다",
      popularity: 250.463,
      poster_path: "/qHjXsSUuolEtbgvYPzRjAuB1VHE.jpg",
      vote_average: 8.7,
      vote_count: 4100,
    },
    {
      backdrop_path: "/8CXw90lEmnJQeUvkgSnl5bEjbQI.jpg",
      first_air_date: "2013-12-02",
      genre_ids: [16, 35, 10765, 10759],
      id: 60625,
      name: "릭 앤 모티",
      origin_country: ["US"],
      original_language: "en",
      original_name: "Rick and Morty",
      overview:
        "술에 절어 사는 천재 과학자 릭은 투정이 심한 10대 손자 모티를 데리고 외계와 대체 현실로 아슬아슬한 모험을 떠난다.",
      popularity: 2247.738,
      poster_path: "/OXy96OFiLDZIz9jT4Byxk1Hk6b.jpg",
      vote_average: 8.7,
      vote_count: 8658,
    },
    {
      backdrop_path: "/A6tMQAo6t6eRFCPhsrShmxZLqFB.jpg",
      first_air_date: "2009-04-05",
      genre_ids: [10759, 16, 10765],
      id: 31911,
      name: "강철의 연금술사 BROTHERHOOD",
      origin_country: ["JP"],
      original_language: "ja",
      original_name: "鋼の錬金術師 FULLMETAL ALCHEMIST",
      overview:
        "죽은 어머니를 살리기 위해 시도한 금단의 연금술로 인해 신체의 일부와 전부를 빼앗긴 에드워드와 알폰스 형제. 동생 알폰스의 신체를 되찾기 위해 현자의 돌을 찾아나선다. 강철의 연금술사 오리지널판 이후 제작되어 원작 만화를 더욱 충실히 반영한 리메이크 버전이다.",
      popularity: 321.622,
      poster_path: "/9SkrxQDka8JAk8tUwb396prUR9J.jpg",
      vote_average: 8.7,
      vote_count: 1765,
    },
    {
      backdrop_path: "/70YdbMELM4b8x8VXjlubymb2bQ0.jpg",
      first_air_date: "2017-03-19",
      genre_ids: [18, 10751],
      id: 70785,
      name: "빨간 머리 앤",
      origin_country: ["CA"],
      original_language: "en",
      original_name: "Anne with an E",
      overview:
        "마음에 불꽃을 품은 여자아이 앤. 나이 든 오누이의 초록 지붕집에 발을 들이게 된다. 커스버트 남매와 낮선 고아 소녀가 가족이 되고 성장하는 과정을 그려낸 시리즈.",
      popularity: 188.668,
      poster_path: "/lKUYNB42aCLYEO7368W5EkjtwAt.jpg",
      vote_average: 8.7,
      vote_count: 4394,
    },
    {
      backdrop_path: "/kU98MbVVgi72wzceyrEbClZmMFe.jpg",
      first_air_date: "2005-02-21",
      genre_ids: [16, 10759, 10765],
      id: 246,
      name: "아바타 아앙의 전설",
      origin_country: ["US"],
      original_language: "en",
      original_name: "Avatar: The Last Airbender",
      overview:
        "물의 부족, 흙의 왕국, 불의 제국, 공기의 유목민은 불의 제국이 일으킨 전쟁으로 평화로운 균형을 잃게 된다. 사람들은 4개의 원소를 모두 다룰 줄 아는 아바타가 나타나 이 세상에 평화를 다시 돌아오기를 기다린다. 물의 부족인 카타라와 소카 남매는 빙하 속에 갇힌 아바타, 공기의 유목민 출신 아앙을 구해준다. 아바타의 운명을 타고난 아앙은 4가지 요소를 다루는 능력을 마스터하고 불의 제국을 물리쳐야 하지만 이 거대한 임무를 감당하는 것이 달갑지 만은 않다. 카타라와 그녀의 용감한 오빠 소카의 도움을 받으며 아앙은 세계를 구하기 위한 위험한 여행을 시작한다.",
      popularity: 77.702,
      poster_path: "/77odCK2vleJcXzFH4mLCMK93ikd.jpg",
      vote_average: 8.7,
      vote_count: 3511,
    },
    {
      backdrop_path: "/nTvM4mhqNlHIvUkI1gVnW6XP7GG.jpg",
      first_air_date: "2019-04-06",
      genre_ids: [16, 10759, 10765],
      id: 85937,
      name: "귀멸의 칼날",
      origin_country: ["JP"],
      original_language: "ja",
      original_name: "鬼滅の刃",
      overview:
        "2019년 4월 Tokyo MX에서 방영을 시작한 애니메이션. 고토게 코요하루의 동명의 만화를 원작으로 한다. 다이쇼 시대, 가난하지만 가족들과 함께 행복한 일상을 보내던 카마도 탄지로. 설이 오기 전에 숯을 잔뜩 팔아 가족들을 배불리 먹여주고 싶었던 탄지로는 혼자 마을로 내려가 숯을 판다. 다음날이 밝고 집에 도착한 탄지로는 가족들이 처참하게 몰살당한 모습을 보고, 유일하게 살아있던 여동생 네즈코를 구하기 위해 그녀를 의사에게 데려가려 하지만 네즈코는 이미 혈귀가 되어 있었다. 가혹한 운명의 사슬을 끊어내고 세상에서 혈귀를 멸하려, 지금 탄지로가 복수의 칼날을 높이 든다.",
      popularity: 177.077,
      poster_path: "/iR6T6QxSJpSQi1S4GGjGWLQkSva.jpg",
      vote_average: 8.7,
      vote_count: 5652,
    },
    {
      backdrop_path: "/6UH52Fmau8RPsMAbQbjwN3wJSCj.jpg",
      first_air_date: "2021-03-25",
      genre_ids: [16, 10765, 10759, 18],
      id: 95557,
      name: "인빈시블",
      origin_country: ["US"],
      original_language: "en",
      original_name: "Invincible",
      overview:
        "마크 그레이슨은 17살에 슈퍼파워를 물려받자 아버지와 함께 지구에서 가장 위대한 영웅 중 하나가 된다. 드디어 꿈이 이루어졌지만 충격적인 사건이 모든 것을 뒤흔든다.",
      popularity: 331.338,
      poster_path: "/yDWJYRAwMNKbIYT8ZB33qy84uzO.jpg",
      vote_average: 8.7,
      vote_count: 3736,
    },
    {
      backdrop_path: "/hMjvf1xGcCbbobFuM2iYR9Tj0Yr.jpg",
      first_air_date: "2022-04-22",
      genre_ids: [18],
      id: 124834,
      name: "하트스토퍼",
      origin_country: ["GB"],
      original_language: "en",
      original_name: "Heartstopper",
      overview:
        "학교에서 만나 친구가 된 찰리와 닉. 우정인 줄만 알았는데 둘 사이에 그 이상의 감정이 싹튼다. 10대들의 학교생활과 풋풋한 사랑을 다룬 성장 드라마 시리즈.",
      popularity: 151.872,
      poster_path: "/p0AtD0ivSlHq2MHY6JFgyhNqAQY.jpg",
      vote_average: 8.7,
      vote_count: 1060,
    },
    {
      backdrop_path: "/jsXKG9uppnPrhqFNhImllyCfLhl.jpg",
      first_air_date: "2016-04-03",
      genre_ids: [10759, 16],
      id: 65930,
      name: "나의 히어로 아카데미아",
      origin_country: ["JP"],
      original_language: "ja",
      original_name: "僕のヒーローアカデミア",
      overview:
        "가공의 세계가 현실로! 이것은 내가 최고의 히어로가 되기까지의 이야기다. 시작은 중국의 경경 시에서 빛을 내뿜는 아기가 태어났다는 뉴스였다. 이후에 각지에서 초현실적 현상이 발견되고 원인도 밝혀지지 않은 채 세월이 흐른다. 세계 총인구의 80%가 어떤 형태로든 특이체질인 초인사회가 된 현재. 선천적으로 타고난 특별한 힘인 ‘개성’을 악용하는 범죄자 ‘빌런’이 갈수록 늘어난다. 이에 마찬가지로 ‘개성’을 가진 자들이 ‘히어로’로서 빌런과 재해에 맞서며 사람들을 구하는 사회를 확립한다. 일찍이 누구나 상상하며 동경하는 ‘히어로’가 현실이 된 세계에서 미도리야 이즈쿠도 히어로가 되는 것을 꿈꾼다. 유명한 히어로를 다수 배출한 유에이 고교에 입학을 목표로 삼고 있지만 아무런 특이체질도 아닌 ‘무개성’의 낙오자로 절망에 빠진다. 그러던 어느 날, 자신이 가장 동경하는 최고의 히어로 올마이트를 우연히 만난 것을 계기로 운명을 크게 바꾸게 된다. 친구, 스승, 라이벌, 빌런... 가지각색의 인물, 수많은 시련과 마주하면서 미도리야 이즈쿠는 최고의 히어로가 되기 위해 성장해 간다. 신세대의 열띤 히어로 스토리가 지금 시작된다!",
      popularity: 136.309,
      poster_path: "/wiXNjJaSSpnhPPa4N6APqpyVNBy.jpg",
      vote_average: 8.7,
      vote_count: 4523,
    },
    {
      backdrop_path: "/uoA7xBzCt3XXqL5fMmB8pmYgABJ.jpg",
      first_air_date: "2016-12-02",
      genre_ids: [18, 10765, 35],
      id: 67915,
      name: "쓸쓸하고 찬란하神: 도깨비",
      origin_country: ["KR"],
      original_language: "ko",
      original_name: "쓸쓸하고 찬란하神-도깨비",
      overview:
        "2016년 12월 tvN에서 방영을 시작한 드라마. 도깨비 설화를 모티브로 김은숙 작가가 3년 전부터 기획하였으며 사극과 현대극을 오가는 판타지적인 요소를 강조한 드라마이다. 당신이 세상에서 멀어지고 있을 때 누군가 세상 쪽으로 등을 떠밀어 주었다면, 그건 신이 당신 곁에 머물다 가는 순간이다.불멸의 삶을 끝내기 위해 인간 신부가 필요한 도깨비, 그리고 그와 기묘한 동거를 시작한 기억상실증 저승사자. 그런 그들 앞에 도깨비 신부라 주장하는 죽었어야 할 운명의 소녀가 나타나며 벌어지는 신비로운 낭만 설화.",
      popularity: 86.19,
      poster_path: "/qZMEiTsNlCQV27hHQE27ZtlPWyv.jpg",
      vote_average: 8.7,
      vote_count: 2550,
    },
    {
      backdrop_path: "/uDgy6hyPd82kOHh6I95FLtLnj6p.jpg",
      first_air_date: "2023-01-15",
      genre_ids: [18],
      id: 100088,
      name: "더 라스트 오브 어스",
      origin_country: ["US"],
      original_language: "en",
      original_name: "The Last of Us",
      overview:
        "정체불명의 팬데믹으로 인류 대다수가 죽거나 괴생명체가 되어버린 근미래,  황량해진 미국을 횡단하는 두 사람의 이야기를 다룬 포스트 아포칼립스물이다.",
      popularity: 429.953,
      poster_path: "/xSwGgk187WVzdlG2DUrYi7BB8mf.jpg",
      vote_average: 8.7,
      vote_count: 4121,
    },
    {
      backdrop_path: "/rqbCbjB19amtOtFQbb3K2lgm2zv.jpg",
      first_air_date: "2013-04-07",
      genre_ids: [10765, 16, 10759],
      id: 1429,
      name: "진격의 거인",
      origin_country: ["JP"],
      original_language: "ja",
      original_name: "進撃の巨人",
      overview:
        "2013년 4월 MBS에서 방영을 시작한 애니메이션. 100여 년 전 갑자기 나타난 거인들에 의해 멸종 위기에 처한 인류는 거대한 벽을 쌓고 그 안에서 살아가고 있다. 에렌은 바깥 세계를 동경하며, 언젠가 저 방벽 밖으로 나가겠다는 꿈을 키우고 있었다. 그러던 어느 날, 60m가 넘는 초대형 거인의 등장으로 벽은 무너지고, 거인들이 벽 안으로 들어와 사람들을 잡아먹기 시작한다. 절규와 죽음으로 가득한 절망적인 상황에서 거인에게 어머니 카를라를 비롯한 소중한 사람들을 빼앗긴 엘런은 복수를 맹세하고, 사무친 복수심을 원동력으로 삼아 만 12세에 훈련병단의 훈련병으로 지원한다.",
      popularity: 221.041,
      poster_path: "/8Cdl0sJQikHQvgn5wKJZ221Ceve.jpg",
      vote_average: 8.7,
      vote_count: 5529,
    },
    {
      backdrop_path: "/2vbE9ajftJ7dkqUAyxDS0WFILx8.jpg",
      first_air_date: "2010-09-06",
      genre_ids: [16, 35],
      id: 31132,
      name: "레귤러 쇼",
      origin_country: ["US"],
      original_language: "en",
      original_name: "Regular Show",
      overview:
        "큰 어치 모디카이와 아메리카너구리 릭비는 공원지기로 일하는 베스트프렌드 이다. 일은 대충대충하고 어떻게하면 신나게 놀지만 고민하는 것이 그들의 일상. 이 둘은 그들의 보스인 벤슨(검볼 자판기)과 동료인 스킵스(예티)를 항상 화나게 만들어 해고의 위협을 받지만 천진난만한 롤리팝 신사이자, 공원 소유자의 아들인 팝스를 즐겁게 만들어 주기 때문에 절대 해고되지 않는다!",
      popularity: 680.655,
      poster_path: "/mS5SLxMYcKfUxA0utBSR5MOAWWr.jpg",
      vote_average: 8.7,
      vote_count: 1790,
    },
    {
      backdrop_path: "/cHyY5z4txdVyGtYMvBJhCqCcJso.jpg",
      first_air_date: "2020-01-10",
      genre_ids: [16, 10765, 18, 10759, 35],
      id: 92685,
      name: "아울 하우스",
      origin_country: ["US"],
      original_language: "en",
      original_name: "The Owl House",
      overview:
        "판타지 세상에 심취해 있는 소녀 루스, 픽션과 현실을 구분하지 못 하고 학교에서 사고를 치다 결국 여름방학 3개월을 정신안정 캠프에서 보내는 처벌을 받게 된다. 하지만 캠프로 떠나기 직전, 우연히 자신의 소설책을 들고 낡은 폐가로 들어가는 부엉이를 뒤쫒다 그토록 동경하던 판타지 세계에서 마녀 이다와 만나게 되는데...",
      popularity: 323.472,
      poster_path: "/zhdy3PcNVE15wj1wrxn45ARZBnx.jpg",
      vote_average: 8.7,
      vote_count: 1386,
    },
    {
      backdrop_path: "/900tHlUYUkp7Ol04XFSoAaEIXcT.jpg",
      first_air_date: "2019-05-06",
      genre_ids: [18],
      id: 87108,
      name: "체르노빌",
      origin_country: ["US"],
      original_language: "en",
      original_name: "Chernobyl",
      overview:
        "2019년 5월 HBO에서 방영을 시작한 미니시리즈. 전 세계를 공포로 몰고 가며 인류 최악의 인재로 기록된 구 소련 체르노빌 원자력 발전소 사태를 재구성했다. 1988년 4월 26일 모스크바, 한 남자가 긴 이야기를 마친 뒤 녹음 테이프를 신문지로 감싼다. 남자는 음식물 쓰레기를 버리는 척, 테이프를 숨겨 집 밖으로 나온다. 아직 어둠에 싸인 거리 한쪽에서 수상한 차가 그를 감시 중이다. 음식물 쓰레기통 옆의 폐건물 유리창 안으로 녹음 테이프를 던진 남자는 집으로 돌아와 고양이에게 밥을 준 뒤 목을 맨다. 벽시계의 바늘이 1시 24분을 가리키고 있다.",
      popularity: 185.329,
      poster_path: "/hAxARod0sqPldhbHa6WZebAZMP3.jpg",
      vote_average: 8.7,
      vote_count: 5410,
    },
    {
      backdrop_path: "/hPea3Qy5Gd6z4kJLUruBbwAH8Rm.jpg",
      first_air_date: "2015-02-08",
      genre_ids: [80, 18],
      id: 60059,
      name: "베터 콜 사울",
      origin_country: ["US"],
      original_language: "en",
      original_name: "Better Call Saul",
      overview:
        "2015년 2월 AMC에서 방영을 시작한 드라마. 브레이킹 배드의 스핀오프 시리즈이다. 브레이킹 배드의 범죄 변호사였던 사울 굿맨의 6년 전 과거인 2002년. 그는 지미 맥길이라는 본명으로 국선 변호사로서 활동하고 있다. 일류 변호사인 형과의 갈등, 과거 시세로에서 사기꾼으로 살던 시절의 기억, 그리고 삼류 변호사가 겪는 어려움들이 지미를 선택의 기로에 계속 내몰게 되며 그 갈림길에서 준법과 불법, 도덕과 이기심 등을 놓고 끊임없이 갈등한다. 그리고 점점 타락해간다.",
      popularity: 476.605,
      poster_path: "/fC2HDm5t0kHl7mTm7jxMR31b7by.jpg",
      vote_average: 8.6,
      vote_count: 4407,
    },
    {
      backdrop_path: "/zbBNp4djy9dGOfMzVBQoP1ZAdET.jpg",
      first_air_date: "2017-06-19",
      genre_ids: [35, 10751],
      id: 72637,
      name: "Once",
      origin_country: ["AR"],
      original_language: "es",
      original_name: "O11CE",
      overview: "",
      popularity: 69.744,
      poster_path: "/zbVwpFNma4RV7HkIVT3aOUmGUJf.jpg",
      vote_average: 8.6,
      vote_count: 1365,
    },
  ],
  total_pages: 153,
  total_results: 3051,
};
